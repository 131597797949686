<template>
<div>
    <el-backtop >
       <div
      style="{
        height: 100%;
        width: 100%;
        background-color: #f2f5f6;
        box-shadow: 0 0 6px rgba(0,0,0, .12);
        text-align: center;
        line-height: 40px;
        color: #1989fa;
      }"
    >返回顶部
      <!-- <i class="el-icon-arrow-up">返回顶部</i> -->
    </div>
  </el-backtop>
<el-card class="box-card">
   
    <div slot="header" class="clearfix">
<el-page-header @back="goback" content="详情页面">
</el-page-header>
    </div>
     
    <div class="title">企业信息详情</div>
 <div class="qyForm">
    
    <el-form :disabled="true" label-width="100px"  :model="businessInfo">
      <el-form-item label="企业名称">
        <el-input v-model="businessInfo.qymc"></el-input>
      </el-form-item>
      <el-form-item label="企业负责人">
        <el-input v-model="businessInfo.fzr"></el-input>
      </el-form-item>
      <el-form-item label="负责人手机号">
        <el-input v-model="businessInfo.fzrtel"></el-input>
      </el-form-item>
       <el-form-item label="企业信用代码">
        <el-input v-model="businessInfo.xydm"></el-input>
      </el-form-item>
      <el-form-item label="企业地址">
        <el-input v-model="businessInfo.addr"></el-input>
      </el-form-item>
      <!-- <el-form-item label="企业类型">
        <el-input :disabled="true" v-model="businessInfo.qylx"></el-input>
      </el-form-item> -->
      <el-form-item label="主营业务">
        <el-input v-model="businessInfo.zyyw"></el-input>
      </el-form-item>
      <el-form-item label="主营产品">
        <el-input v-model="businessInfo.zycp"></el-input>
      </el-form-item>
      <el-form-item label="经营规模">
        <el-input v-model="businessInfo.jygm"></el-input>
      </el-form-item>
      <el-form-item   label="企业简介" >
        <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 6 }" v-model="businessInfo.dwjj"></el-input>
      </el-form-item>
      <el-form-item class="btns">
        <!-- <el-button type="primary" @click="submitqyInfo">提交修改</el-button> -->
      </el-form-item>
    </el-form>

  </div>
  <div class="rightarea">
       <div class="block">
    <span class="demonstration">企业营业执照</span>
    <el-image :src="src"  style="width: 20vw; height: 10vw"></el-image>
  </div>
  <div class="block">
    <span class="demonstration">身份证件</span>
    <el-image :src="src" style="width: 20vw; height: 10vw">
      <div slot="placeholder" class="image-slot">
        加载中<span class="dot">...</span>
      </div>
    </el-image>
  </div>
  </div>
  <div>产品列表</div>
    <el-table
      :data="productdata"
      @row-click="getaddr"
      style="width: 100%">
      <el-table-column
        type="index"
        label="编号"
      >
      </el-table-column>
      <el-table-column
        prop="cpmc"
        label="产品名称"
        width="180">
      </el-table-column>
      <el-table-column
        prop="ms"
        label="产品描述">
      </el-table-column>
      <el-table-column
        prop="bzq"
        label="保质期（天）">
      </el-table-column>

    </el-table>
       <!-- 分页区域 -->
      <el-pagination
        background
        layout="prev, pager, next,total"
        :total="prototal"
        :page-size="queryInfo.pageSize"
        @current-change="handleCurrentChange2"
      >
      </el-pagination>
      <el-divider></el-divider>
        <div>产地列表</div>
   <el-table
      :data="tableData"
      @row-click="getaddr"
      style="width: 100%">
      <el-table-column
        type="index"
        label="编号"
      >
      </el-table-column>
      <el-table-column
        prop="chandi"
        label="产地名称"
        width="180">
      </el-table-column>
      <el-table-column
        prop="detailaddress"
        label="产地地址">
      </el-table-column>
      <el-table-column
        prop="yt"
        label="用途">
      </el-table-column>

    </el-table>
       <!-- 分页区域 -->
      <el-pagination
        background
        layout="prev, pager, next,total"
        :total="total"
        :page-size="queryInfo.pageSize"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
       <div id="mapDiv" class="mapDiv" ref="mapDiv"></div>
</el-card>
  

</div>
  
</template>

<script>
import tiandi from "../../../../../assets/js/maptiandi"
export default {
    data(){
        return{
            qyid:"",
            businessInfo:{},
            tableData:[],
            productdata:[],
            prototal:0,
            total:0,
            queryInfo:{
                pageSize:5
            },
             src: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg'
        }
    },
    created(){
       this.qyid=this.$route.query.id 
       this.getqydetail()
       

    },
    methods:{
        roadMap(lng,lat ) {
    console.log(this.tableData[0])
      tiandi.init().then(
          
        T => {
          this.T = T;
          const imageURL = "http://t0.tianditu.gov.cn/img_c/wmts?tk=b02dec10c18d1827f87ef79acf6cd3c0";
          const lay = new T.TileLayer(imageURL, { minZoom: 1, maxZoom: 18 });
          const config = { layers: [lay], name: 'TMAP_SATELLITE_MAP' };
          this.map = new T.Map('mapDiv');
          
          
          const ctrl = new T.Control.MapType();
          this.map.addControl(ctrl);
          this.map.centerAndZoom(new T.LngLat(lng, lat),8);
          // this.map.setStyle('indigo');
          var arrayObj = new Array();
          var icon = new T.Icon({
                iconUrl: "http://api.tianditu.gov.cn/img/map/markerA.png",
                iconSize: new T.Point(25, 35),
                iconAnchor: new T.Point(10, 25)
            });
          for(var i=0;i<this.tableData.length;i++){
            var marker = new T.Marker(new T.LngLat(this.tableData[i].lng,this.tableData[i].lat), {icon: icon});
            this.map.addOverLay(marker);
            arrayObj.push(marker)
          }
          console.log(marker)
          
        //   var markers = new T.MarkerClusterer(this.map, {markers: arrayObj});
        }).catch()
        
    },
    handleCurrentChange2(e){

    },
        handleCurrentChange(e){

        },
        goback(){
            this.$router.back(-1)
        },
        getqydetail(){
            this.$request.post('qydetail',{'bh':this.qyid}).then(res=>{
                this.businessInfo=res.data.data.data
                this.tableData =res.data.data.chandi
                this.productdata =res.data.data.cpmc
                this.roadMap(this.tableData[0].lng,this.tableData[0].lat)
            })
        },
        getaddr(row){
            console.log(row.bh)
            this.roadMap(row.lng,row.lat)
        }

    }

}
</script>

<style>
.title{
    text-align: center;
    font-size: 25px;
    padding-bottom: 25px;
}
.qyForm .el-input.is-disabled .el-input__inner,.el-textarea__inner{
    color: #000!important;
}
.qyForm{
    display: inline-block;
    width: 50%;
}
.rightarea{
    display: inline-block;
    width: 49%;
    text-align: center;
    vertical-align: top;
}
.demonstration{
    display: block;
    color: #8492a6;
    font-size: 14px;
    margin-bottom: 20px;
}
.mapDiv{
    width: 95%;
    height: 600px;
    margin: 0 auto;
}
</style>